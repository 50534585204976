.services-teasers-grid .view-content {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.services-teasers-grid .view-content .views-row {
  flex: 0 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  flex-grow: 1;
  flex-basis: 100%;
  max-width: 100%;
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .services-teasers-grid.view-cols-2 .views-row {
    flex: 0 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    flex-grow: 1;
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 768px) {
  .services-teasers-grid.view-cols-3 .views-row {
    flex: 0 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    flex-grow: 1;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
}
