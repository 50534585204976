@import "./_sass-essentials/essentials.scss";

.services-teasers-grid {
  .view-content {
    @include fg-row();
    justify-content: center;

    .views-row {
      @include fg-xxs-col(12);
      margin-bottom: $l-gutter;
    }
  }

  &.view-cols-1 {

  }

  &.view-cols-2 {
    .views-row {
      @include fg-sm-col(6);
    }
  }

  &.view-cols-3 {
    .views-row {
      @include fg-sm-col(4);
    }
  }
}
